import { Toggle, toggleAutoInit } from "tiny-ui-toggle";
import Swiper, { Navigation } from "swiper";

toggleAutoInit();

document.querySelector("#main-nav").addEventListener("toggle", (event) => {
  if (event.detail) console.log(`Action: ${event.detail.action}, Active: ${event.detail.active}`);
});

// init Swiper:
const swiper = new Swiper(".swiper", {
  // configure Swiper to use modules
  modules: [Navigation],
  slidesPerView: 1,
  spaceBetween: 20,
  navigation: {
    nextEl: ".button--next",
    prevEl: ".button--prev",
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
    },
    940: {
      slidesPerView: 3,
    },
  },
});
